import { Asset } from "contentful";

import { getHttpsUrlFromContentfulImage } from "@helpers/contentful-image-link";
import { BaseContentfulModel } from "@models/BaseContentfulModel";
import {
  TypeModuleHospital,
  TypePathwayFields,
  TypePathwayModuleFields,
  TypePathwayModuleFocus,
  TypePathwayModuleQuestionsQuiz,
} from "@models/Contentful";

export const moduleMapper = (module: BaseContentfulModel<TypePathwayModuleFields>) => {
  return {
    ...module,
    featuredImage: getHttpsUrlFromContentfulImage(module.featuredImage?.fields.file.url),
    focus: module.focus?.map(focusMapper),
    assess: module?.assess?.map(assessMapper) ?? [],
    apply: module.apply?.map(applyMapper) ?? [],
    attachments: module.attachments?.map(attachmentMapper) ?? [],
    hospitalsRelease: module.hospitalsRelease?.map(hospitalRelease) ?? [],
  };
};

export const modulesMapper = (modules: BaseContentfulModel<TypePathwayModuleFields>[]) => {
  return modules.map(moduleMapper);
};

export function pathwayMapper(pathway: BaseContentfulModel<TypePathwayFields>) {
  return {
    id: pathway.id,
    title: pathway.title,
    modules: pathway.modules
      .filter(({ fields }) => !!fields)
      .map(({ sys, fields }) => {
        return {
          id: sys.id,
          ...fields,
          featuredImage: getHttpsUrlFromContentfulImage(fields.featuredImage?.fields.file.url),
          apply: fields.apply?.map(applyMapper) ?? [],
          focus: fields.focus?.map(focusMapper),
          assess: fields?.assess?.map(assessMapper) ?? [],
          attachments: fields.attachments?.map(attachmentMapper) ?? [],
          hospitalsRelease: fields.hospitalsRelease?.map(hospitalRelease) ?? [],
        };
      }),
  };
}

// ===========================================================================

function assessMapper({ sys, fields }: TypePathwayModuleQuestionsQuiz) {
  return { id: sys.id, ...fields };
}

function applyMapper({ sys, fields }: TypePathwayModuleQuestionsQuiz) {
  return { id: sys.id, ...fields };
}

function focusMapper({ sys, fields }: TypePathwayModuleFocus) {
  return { id: sys.id, ...fields };
}

function attachmentMapper({ fields }: Asset) {
  return {
    fileName: fields.title,
    file: getHttpsUrlFromContentfulImage(fields.file.url),
  };
}

function hospitalRelease({ fields }: TypeModuleHospital) {
  if (!fields) {
    return {};
  }

  return {
    releaseDate: fields?.releaseDate ?? "",
    hospitalId: fields.hospital.fields?.databaseId ?? "",
  };
}
