import { PathwayContent } from "@/types/pathway";

import { modulesMapper, pathwayMapper } from "@mappers/pathway";
import { TypePathwayFields, TypePathwayModuleFields } from "@models/Contentful";
import { getEntries, getEntry } from "@services/contentful";

/**
 * Retrieves a module by its module ID.
 * @param moduleId - The ID of the module to retrieve.
 * @returns A promise that resolves to the module with the specified ID.
 */
export async function getModuleByModuleId(moduleId: string) {
  return await getEntry<TypePathwayModuleFields>(moduleId);
}

/**
 * Retrieves the pathway modules based on the provided moduleId.
 * @param moduleSlug - The ID of the module to retrieve.
 * @returns A Promise that resolves to the pathway modules.
 */
export async function getPathwayModulesBySlug(moduleSlug: string) {
  const modules = await getEntries<TypePathwayModuleFields>("pathwayModule", {
    "fields.slug": moduleSlug,
    include: 4,
  });

  return modulesMapper(modules);
}

/**
 * Retrieves the pathways from the Contentful API.
 * @returns A promise that resolves to an array of pathway entries.
 */
export async function getPathways(params?: Record<string, string>) {
  return getEntries<TypePathwayFields>("pathway", {
    include: 1,
    ...params,
  });
}

/**
 * Retrieves a pathway by its ID.
 * @param pathwayId - The ID of the pathway to retrieve.
 * @returns A Promise that resolves to the pathway object.
 */
export async function getPathwayById(pathwayId: string) {
  return getEntry<TypePathwayFields>(pathwayId);
}

/**
 * Retrieves the contentful pathway with the specified ID.
 * @param pathwayId - The ID of the pathway to retrieve.
 * @returns A promise that resolves to the contentful pathway.
 */
export async function getContentfulPathway(pathwayId: string): Promise<PathwayContent> {
  const pathway = await getEntry<TypePathwayFields>(pathwayId, { include: 4 });

  return pathwayMapper(pathway) as PathwayContent;
}
