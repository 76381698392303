"use client";

import { type ReactNode, createContext, useContext } from "react";

import { ModuleContent, PathwayContent } from "@/types/pathway";
import { useQuery } from "@tanstack/react-query";

import { getContentfulPathway } from "@services/pathway/contentful";

import { useAuth } from "./User";

type PathwayContentContextProps = {
  pathway?: PathwayContent;
  isLoading: boolean;
  getModuleById: (moduleId: string) => ModuleContent | undefined;
};

export const PathwayContentContext = createContext<PathwayContentContextProps>(
  {} as PathwayContentContextProps
);

export interface PathwayContentProviderProps {
  children: ReactNode;
}

export const PathwayContentProvider = ({ children }: PathwayContentProviderProps) => {
  const { contentfulPathwayId } = useAuth();

  const { data: pathway, isLoading } = useQuery<PathwayContent>({
    queryKey: ["pathway-content", contentfulPathwayId],
    queryFn: () => getContentfulPathway(contentfulPathwayId as string),
    enabled: !!contentfulPathwayId,
  });

  function getModuleById(moduleId: string) {
    return pathway?.modules.find(module => module.id === moduleId);
  }

  return (
    <PathwayContentContext.Provider value={{ pathway, isLoading, getModuleById }}>
      {children}
    </PathwayContentContext.Provider>
  );
};

export const usePathwayContent = () => {
  return useContext<PathwayContentContextProps>(PathwayContentContext);
};
