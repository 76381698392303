"use client";

import { ThemeProvider } from "@emotion/react";
import dynamic from "next/dynamic";
import { PostHogProvider } from "posthog-js/react";
import { ReactNode } from "react";

import "@/styles/globals.css";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import { AppContextProvider } from "@context/AppContext";
import { PathwayContentProvider } from "@context/PathwayContent";
import PopupProvider from "@context/PopupContext";
import UserProviderContext from "@context/User";
import { initiatePostHog } from "@libs/posthog";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { GoogleAnalytics } from "nextjs-google-analytics";
import posthog from "posthog-js";

import { assembleTheme } from "@styles/theme";

const ToasterNotification = dynamic(() => import("@components/common/ToasterNotification"));

initiatePostHog();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24,
    },
  },
});

const asyncStoragePersister = createAsyncStoragePersister({
  storage: typeof window !== "undefined" ? window.localStorage : undefined,
});

export default function Providers({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider theme={assembleTheme}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister: asyncStoragePersister }}
      >
        <UserProvider>
          <UserProviderContext>
            <AppContextProvider>
              <PathwayContentProvider>
                <PostHogProvider client={posthog}>
                  <PopupProvider>
                    <ToasterNotification />
                    <GoogleAnalytics trackPageViews />
                    <ProgressBar color="#fc6d1d" options={{ showSpinner: false }} shallowRouting />
                    {children}
                  </PopupProvider>
                </PostHogProvider>
              </PathwayContentProvider>
            </AppContextProvider>
          </UserProviderContext>
        </UserProvider>
      </PersistQueryClientProvider>
    </ThemeProvider>
  );
}
