import { deliveryApi, previewApi } from "@configs/libs/contentful";

import { BaseContentfulModel } from "@models/BaseContentfulModel";

/*
 * Content Delivery API
 */
async function getEntries<T>(
  contentType: string,
  params = {}
): Promise<Array<BaseContentfulModel<T>>> {
  const response = await deliveryApi.getEntries<T>({ content_type: contentType, ...params });
  return response.items.map(({ fields, sys: { id, createdAt, updatedAt } }) => {
    return { ...fields, id, createdAt, updatedAt };
  });
}

async function getEntry<T>(entryId: string, params = {}): Promise<BaseContentfulModel<T>> {
  const response = await deliveryApi.getEntry<T>(entryId, params);
  const {
    fields,
    sys: { id, createdAt, updatedAt },
  } = response;

  return { ...fields, id, createdAt, updatedAt };
}

type PaginatedEntries<T> = {
  items: Array<BaseContentfulModel<T>>;
  total: number;
};

async function getPaginatedEntries<T>(
  contentType: string,
  limit = 10,
  skip = 0,
  params = {}
): Promise<PaginatedEntries<T>> {
  const response = await deliveryApi.getEntries<T>({
    content_type: contentType,
    limit,
    skip,
    ...params,
  });
  const items = response.items.map(
    ({ fields, sys: { id, createdAt, updatedAt }, metadata: { tags } }) => {
      return { ...fields, id, createdAt, updatedAt, tags };
    }
  );
  return { items, total: response.total };
}

/*
 * Content Management API
 *
 * Update entry fields in Contentful
 * @name updateEntry
 * @usage updateEntry("entryId", { fieldName: { "en-US": fieldValue } })
 */

async function getPreviewEntry<T>(entryId: string): Promise<BaseContentfulModel<T>> {
  const response = await previewApi.getEntry<T>(entryId);
  const {
    fields,
    sys: { id, createdAt, updatedAt },
    metadata: { tags },
  } = response;

  return { ...fields, id, createdAt, tags, updatedAt };
}

export { getEntries, getEntry, getPaginatedEntries, getPreviewEntry };
