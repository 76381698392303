import { Frank_Ruhl_Libre, Inter } from "next/font/google";

import { secondary } from "./colors";

export const inter = Inter({
  subsets: ["latin"],
  variable: "--inter",
  display: "swap",
});

export const frankRuhlLibre = Frank_Ruhl_Libre({
  subsets: ["latin"],
  variable: "--frank-ruhl-libre",
  display: "swap",
});

export const DEFAULT_TYPOGRAPHY = "var(--inter)";
export const DEFAULT_FONT_SIZE = 16;

/**
 * Base = 16px
 **/
export const fontSize = {
  xs: ".8rem", // 12px
  s: ".9rem", // 14px
  m: "1rem", // 16px
  l: "1.25rem", // 20px
  xl: "1.5rem", // 24px
  xxl: "3rem", // 48px
};

export const defaultTypography = {
  title: {
    fontFamily: frankRuhlLibre.style.fontFamily,
    fontSize: fontSize.xxl,
    color: secondary[130],
    fontWeight: 600,
  },
  h1: {
    fontSize: fontSize.xxl,
    fontWeight: 500,
  },
  h2: {
    fontSize: fontSize.xxl,
    fontWeight: 500,
  },
  h3: {
    fontSize: fontSize.xl,
    fontWeight: 500,
    color: secondary[130],

    "@media (max-width: 600px)": {
      fontSize: fontSize.m,
    },
  },
  h4: {
    fontSize: fontSize.l,
    fontWeight: 500,
    lineHeight: "140%",
    color: secondary[130],
  },
  h5: {
    fontSize: fontSize.m,
    lineHeight: 1.2,
    fontWeight: 500,
  },
  h6: {
    fontSize: fontSize.s,
    lineHeight: 1.2,
  },
  button: {
    fontSize: fontSize.m,
    lineHeight: 1.4,
    fontWeight: 400,
    textTransform: "none",
  },
  caption: {
    fontSize: fontSize.xs,
    textTransform: "uppercase",
    color: secondary[70],
    letterSpacing: "0.05em",
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: fontSize.l,
    color: secondary[130],
    lineHeight: 1.5,

    "@media (max-width: 600px)": {
      fontSize: fontSize.m,
    },
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: fontSize.l,
    color: secondary[130],
    lineHeight: 1.5,
  },
  body1: {
    fontSize: fontSize.m,
    color: secondary[150],
    lineHeight: "140%",

    "@media (max-width: 600px)": {
      fontSize: fontSize.s,
    },
  },
  body2: {
    fontWeight: 400,
    fontSize: fontSize.xs,
    lineHeight: 1.5,
    color: secondary[130],
  },
};
