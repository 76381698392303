import { createClient } from "contentful";
import * as contentfulManagement from "contentful-management";

const spaceId = process.env.CONTENTFUL_SPACE_TOKEN as string;

/*
 * Content Delivery API
 * https://www.contentful.com/developers/docs/references/content-delivery-api/
 */
const deliveryApi = createClient({
  space: spaceId,
  accessToken: process.env.CONTENTFUL_ACCESS_TOKEN as string,
  host: "cdn.contentful.com",
});

/*
 * Content Preview API
 * https://www.contentful.com/developers/docs/references/content-preview-api/
 */
const previewApi = createClient({
  space: spaceId,
  accessToken: process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN as string,
  host: "preview.contentful.com",
});

/**
 * Content Management API
 * https://www.contentful.com/developers/docs/references/content-management-api/
 */
const managementApi = contentfulManagement.createClient(
  {
    accessToken: process.env.CONTENTFUL_CMA_ACCESS_TOKEN as string,
  },
  {
    type: "plain",
    defaults: {
      spaceId,
      environmentId: "master",
    },
  }
);

export { deliveryApi, managementApi, previewApi };
